import axios from "axios";
import { commonAPI } from "@/modules/api-config";
import { reportError } from "@/utils/error-reporting";

async function getFacilities() {
    try {
        let response = await axios.get(
            `${commonAPI}/property-facilities?limit=100`
        );
        return response.data.data;
    } catch (error) {
        reportError(error, "Get Facilities");
        throw error;
    }
}
async function getAmenities() {
    try {
        let response = await axios.get(
            `${commonAPI}/property-amenities?limit=100`
        );
        return response.data.data;
    } catch (error) {
        reportError(error, "Get Amenities");
        throw error;
    }
}
async function getPropertyTags() {
    try {
        let response = await axios.get(`${commonAPI}/hashtags?limit=100`);
        return response.data.data;
    } catch (error) {
        reportError(error, "Get Hashtags");
        throw error;
    }
}
async function getPropertyTypes({ queries = {} }) {
    try {
        let response = await axios.get(
            `${commonAPI}/property-types?limit=100`,
            { params: queries }
        );
        return response.data.data;
    } catch (error) {
        reportError(error, "Get Property Types");
        throw error;
    }
}

async function getLotTypes({ queries = {} }) {
    try {
        let response = await axios.get(`${commonAPI}/lot-types?limit=100`, {
            params: queries
        });
        return response.data.data;
    } catch (error) {
        return false;
    }
}

export {
    getFacilities,
    getAmenities,
    getPropertyTags,
    getPropertyTypes,
    getLotTypes
};
