<template>
  <div class="unit-type-edit">
    <div class="card">
      <fd-form class="modal-content-wrapper" @submit.prevent="submitForm">
        <!-- Content -->
        <modal-body class="p-2">
          <div v-show="isLoading" class="spinner-container">
            <spinner></spinner>
          </div>
          <div v-show="!isLoading">
            <h4 class="pt-1 pb-4">Edit Unit Type</h4>

            <div class="row">
              <fd-input
                v-model="unitType.name"
                class="col-12 px-1 mb-2"
                label="Unit Type Name"
                name="unitTypeName"
                type="text"
                :validators="[validator.required]"
              >
              </fd-input>
              <fd-select
                v-model="unitType.propertyTypeId"
                class="col-12 px-1 mb-2"
                label="Property Type"
                :options="propertyTypeOptions"
                :validators="[validator.required]"
              >
              </fd-select>
              <fd-multi-select
                v-model="unitType.projectPhases"
                class="col-12 px-1 mb-2"
                label="Available Phases"
                selectText="Select available phases"
                :options="phaseOptions"
                :validators="[validator.required]"
                optionValueKey="id"
                optionLabelKey="name"
              ></fd-multi-select>

              <div class="col-12 mb-2 px-1 mb-2">
                <fd-input
                  v-model="unitType.bookingFee"
                  class="col-12 mb-1"
                  label="Booking Fee"
                  name="bookingFee"
                  type="text"
                  :validators="[validator.required, validator.price]"
                >
                </fd-input>
                <div class="d-flex align-items-center">
                  <fd-checkbox
                    v-model="unitType.isEditBookingFee"
                    class="d-inline-block"
                    label="Editable at unit"
                  >
                  </fd-checkbox>
                  <button
                    type="button"
                    class="btn ml-2"
                    v-tooltip="
                      `Check this if you want to make <b>Booking Fee</b> editable at unit, else it will inherit the data from this unit type.`
                    "
                  >
                    <i class="fas fa-info"></i>
                  </button>
                </div>
              </div>

              <fd-input
                v-model="unitType.completionDate"
                class="col-12 px-1 mb-2"
                label="Completion Date"
                name="unitTypeCompletionDate"
                type="text"
                :validators="[validator.yyyymm]"
              >
              </fd-input>

              <!-- VR Link -->
              <fd-input
                v-model="unitType.vrLink"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="VR Tour Link"
                name="projectVRLink"
                type="url"
                placeholder="https://"
                :validators="[validator.website]"
              >
              </fd-input>

              <div class="col-12 px-1 mb-2 sitemaps">
                <image-uploader
                  v-model="unitType.siteMap"
                  imgWrapperClass="xl-col-3 md-col-4 sm-col-4 col-6 px-1 py-1"
                  label="Site Plans"
                  multiple
                  watermark
                  required
                  @error="
                    (error, imageName) => {
                      $reportError(error, 'Upload siteMap (Edit U.Type)');
                    }
                  "
                >
                </image-uploader>
              </div>
            </div>
          </div>
        </modal-body>

        <!-- Action Footer -->
        <modal-footer class="p-2">
          <div class="col-12 text-right">
            <button type="button" class="btn mr-1" @click="$emit('cancel')">
              Cancel
            </button>
            <button class="btn main">Update</button>
          </div>
        </modal-footer>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  yyyymm,
  website,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import * as propertyAPI from "@/modules/Property/api/property";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectPhaseAPI from "@/modules/Project/api/projectPhase";
import ProjectUnitTypeAPI from "@/modules/Project/api/projectUnitType";
import ProjectUnitTypeModel from "@/modules/Project/models/ProjectUnitTypeModel";

export default {
  components: {
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    unitTypeId: {
      type: [Number, String],
      required: true
    },
    propertyCategory: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      phaseAPI: new ProjectPhaseAPI(this.type),
      unitTypeAPI: new ProjectUnitTypeAPI(this.type),

      isLoading: false,
      propertyTypeOptions: [],
      phaseOptions: [],

      unitType: {
        projectId: "",
        name: "",
        propertyTypeId: "",
        siteMap: [],
        projectPhases: [],
        vrLink: "",
        bookingFee: "",
        isEditBookingFee: false
      },

      validator: {
        required: required,
        yyyymm: yyyymm,
        website: website,
        price: price
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        await this.getUnitTypeData();
        this.propertyTypeOptions = await this.getPropertyTypes();
        this.phaseOptions = await this.getPhases();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    // ============================== API RELATED ==============================
    async getUnitTypeData() {
      try {
        let data = await this.unitTypeAPI.getUnitType({
          id: this.unitTypeId,
          include: "projectPhases"
        });
        this.unitType = ProjectUnitTypeModel.getToEditResponse(data);
      } catch (error) {
        this.$reportError(error, "Get Unit Type to Edit");
        console.log(error);
      }
    },

    async getPropertyTypes() {
      let queries = {};
      queries.propertyCategory = this.propertyCategory;
      try {
        let data = await propertyAPI.getPropertyTypes({ queries: queries });
        return data;
      } catch (error) {
        throw error;
      }
    },
    async getPhases() {
      try {
        let data = await this.phaseAPI.getProjectPhases({
          queries: {
            "project:id": this.unitType.projectId
          }
        });
        return data.data;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get phase options. Please try again later."
        });
        throw error;
      }
    },

    submitForm() {
      this.$emit(
        "submit",
        this.unitTypeId,
        ProjectUnitTypeModel.postPayload(this.unitType)
      );
    }
  }
};
</script>

<style lang="scss">
.unit-type-edit {
  @extend %formDesign;
  width: max-content;

  @media #{$breakpoint-up-lg} {
    .modal-body {
      min-width: 800px;
    }
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    min-width: 300px;
  }
}
</style>
